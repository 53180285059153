.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 70px;
  background-color: white;
  box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.2);
}

.opacity-40 {
  opacity: 0.4;
}