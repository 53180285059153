.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.btn-red {
  background-color: #ff0000;
  border-radius: 20px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  height: 40px;
  width: 100%;
  border: none;
  outline: none;
  cursor: pointer;
  margin-top: 20px;
}
.btn-red:hover {
  background-color: #c90303;
  transition: 0.3s;
}
