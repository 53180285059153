.frame {
  width: 100vw;
  height: 100%;
  z-index: 1;
}

.h-100-vh {
  height: 100vh;
}

.pb-70 {
  padding-bottom: 80px;
}

@media (max-width: 500px) {
  .h-100-vh {
    height: 87vh;
  }
}