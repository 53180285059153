.profile-img {
  border-radius: 50px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  width: 80px;
  height: 80px;
}

.b-profile {
  border-radius: 20px;
  border: 1px solid #cecece;
}