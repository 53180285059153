.f-24 {
  font-size: 24px;
}

.f-40 {
  font-size: 40px;
}

.f-20 {
  font-size: 20px;
}

.br-50 {
  border-radius: 50px;
}

.fw-700 {
  font-weight: 700;
}

.f-25 {
  font-size: 25px;
}

.f-30 {
  font-size: 30px;
}

.pointer {
  cursor: pointer;
}

.discover-container {
  max-width: 500px;
  padding-bottom: 70px;
}

.search-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 25px;
}

.feature-image {
  width: 100%;
  height: 220px;
  object-fit: cover;
  border-radius: 10px;
  border: 1px solid #cecece;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
}

@media (max-width: 500px) {
  .feature-image {
    height: 177px;
  }

  .f-25 {
    font-size: 20px;
  }

  .f-20 {
    font-size: 16px;
  }
}